.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar,
.navbar-default {
    background-image: linear-gradient(to bottom,#EFF8F7 0,#EFF8F7 100%) !important;
}

.navbar-light .navbar-brand {
  color: #49bbb0 !important;
  font-weight: 700;
  letter-spacing: 1px;
}

#footer {
  background: #fff;
  padding: 0 0 30px 0;
  color: #444444;
  font-size: 14px;
  background: #f1f6fe;
}
#footer .footer-newsletter {
  padding: 50px 0;
  background: #f1f6fe;
  text-align: center;
  font-size: 15px;
}
#footer .footer-newsletter h4 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
}


#footer .footer-top {
  padding: 60px 0 30px 0;
  background: #fff;
}
#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}
#footer .footer-top .footer-contact h3 {
  font-size: 24px;
  margin: 0 0 15px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
}
#footer .footer-top .footer-contact h3 span {
  color: #106eea;
}

#footer .footer-top .footer-contact p span {
  color: #106eea;
}
#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Roboto", sans-serif;
  color: #777777;
}
#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  color: #444444;
  position: relative;
  padding-bottom: 12px;
}
#footer .footer-top .footer-links {
  margin-bottom: 30px;
}
#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #106eea;
  font-size: 18px;
  line-height: 1;
}
#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}
#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}
#footer .footer-top .footer-links ul a {
  color: #777777;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}
#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #106eea;
}
#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #49bbb0;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
#footer .footer-top .social-links a:hover {
  background: #12611dbd;
  color: #fff;
  text-decoration: none;
}
#footer .copyright {
  text-align: center;
  float: left;
}
#footer .credits {
  float: right;
  text-align: center;
  font-size: 13px;
  color: #444444;
}
@media (max-width: 768px) {
  #footer .copyright, #footer .credits {
    float: none;
    text-align: center;
    padding: 2px 0;
  }
}

#hero {
  width: 100%;
  height: 55vh;
  background: url("../public/assets/pharma-background.jpg") top left;
  background-size: cover;
  position: relative;
}
#hero:before {
  content: "";
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
#hero .container {
  position: relative;
}
#hero h1 {
  margin: 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #222222;
  font-family: "Poppins", sans-serif;
}
#hero h1 span {
  color: #49bbb0;
}
#hero h2 {
  color: #555555;
  margin: 5px 0 30px 0;
  font-size: 24px;
  font-weight: 400;
}
#hero .btn-get-started {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1.5px;
  display: inline-block;
  padding: 10px 28px;
  border-radius: 4px;
  transition: 0.5s;
  color: #fff;
  background: #49bbb0;
}
#hero .btn-get-started:hover {
  background: #12611dbd;
}
#hero .btn-watch-video {
  font-size: 16px;
  transition: 0.5s;
  margin-left: 25px;
  color: #222222;
  font-weight: 600;
  display: flex;
  align-items: center;
}
#hero .btn-watch-video i {
  color: #106eea;
  font-size: 32px;
  transition: 0.3s;
  line-height: 0;
  margin-right: 8px;
}
#hero .btn-watch-video:hover {
  color: #49bbb0;
}
#hero .btn-watch-video:hover i {
  color: #49bbb0;
}
@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}
@media (max-width: 768px) {
  #hero {
    height: 50vh;
  }
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  #hero .btn-get-started, #hero .btn-watch-video {
    font-size: 13px;
    letter-spacing: 0.5px;
    text-align: center;
  }
}
@media (max-height: 500px) {
  #hero {
    height: 120vh;
  }
}

a:link {
  text-decoration: none;
}

#topbar {
  background: #49bbb0;
  height: 30px;
  font-size: 14px;
  transition: all 0.5s;
  color: #fff;
  padding: 0;
}
#topbar .contact-info i {
  font-style: normal;
  color: #fff;
}
#topbar .contact-info i a, #topbar .contact-info i span {
  padding-left: 5px;
  color: #fff;
}
#topbar .contact-info i a {
  line-height: 0;
  transition: 0.3s;
  transition: 0.3s;
}
#topbar .contact-info i a:hover {
  color: #fff;
  text-decoration: underline;
}
#topbar .social-links a {
  color: rgba(255, 255, 255, 0.7);
  line-height: 0;
  transition: 0.3s;
  margin-left: 20px;
}
#topbar .social-links a:hover {
  color: white;
}
#about {
  width: 100%;
  height: 20%;
  background: #EFF8F7;
}
#about h1 {
  margin: 0 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: black;
}

#about .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #49bbb0;;
  border: 2px solid #49bbb0;
}
#about .btn-get-started:hover {
  background: #49bbb0;
  color: #fff;
}
#about .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
#about h1 span {
  color: #49bbb0;
}

#about h2 {
  color: #484848;
  margin-bottom: 50px;
  font-size: 24px;
 font-size: 18px;
 text-align: justify;
 font-family: "Roboto", sans-serif;
}
#about h3 {
  color: #484848;
  margin-bottom: 50px;
  font-size: 24px;
 font-size: 18px;
 font-family: "Roboto", sans-serif;
}

@media (min-width: 1024px) {
  #about {
    background-attachment: fixed;
  }
}
@media (max-width: 991px) {
  #about {
    height: 120vh;
  }
  #about .animated {
    -webkit-animation: none;
    animation: none;
  }
  #about .about-img {
    text-align: center;
  }
  #about .about-img img {
    width: 50%;
  }
}
@media (max-width: 768px) {
  #about {
  }
  #about h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #about h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  #about .about-img img {
    width: 70%;
  }
}
@media (max-width: 575px) {
  #about .about-img img {
    width: 80%;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

.services {
  background: #EFF8F7;
}

.services .icon-box {
  padding: 30px;
  position: relative;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  z-index: 1;
}
.services .icon-box::before {
  content: "";
  position: absolute;
  background: #49bbb0;
  right: -60px;
  top: -40px;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  transition: all 0.3s;
  z-index: -1;
}
.services .icon-box:hover::before {
  background: #49bbb0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 0px;
}
.services .icon {
  margin: 0 auto 20px auto;
  padding-top: 10px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  background: #49bbb0;
  transition: all 0.3s ease-in-out;
}
.services .icon i {
  font-size: 36px;
  line-height: 1;
  color: #fff;
}
.services .title {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}
.services .title a {
  color: #111;
}
.services .description {
  font-size: 15px;
  line-height: 28px;
  margin-bottom: 0;
}
.services .icon-box:hover .title a, .services .icon-box:hover .description {
  color: #fff;
}
.services .icon-box:hover .icon {
  background: #fff;
}
.services .icon-box:hover .icon i {
  color: #49bbb0;
}
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #f7fbfe;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-background {
  background: #F1F6FE;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  color: #222222;
}
.section-title h2::before, .section-title h2::after {
  content: "";
  width: 50px;
  height: 2px;
  background: #49bbb0;
  display: inline-block;
}
.section-title h2::before {
  margin: 0 15px 10px 0;
}
.section-title h2::after {
  margin: 0 0 10px 15px;
}
.section-title p {
  margin: 15px 0 0 0;
}

.team {
  background: #EFF8F7;
  padding: 2vh 0;
}
.team .member {
  overflow: hidden;
  text-align: center;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
  transition: 0.3s;
}
.team .member .member-img {
  position: relative;
  overflow: hidden;
}
.team .member .member-img:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: url(../public/assets/team-shape.svg) no-repeat center bottom;
  background-size: contain;
  z-index: 1;
}
.team .member .social {
  position: absolute;
  right: -100%;
  top: 30px;
  opacity: 0;
  border-radius: 4px;
  transition: 0.5s;
  background: rgba(255, 255, 255, 0.3);
  z-index: 2;
}
.team .member .social a {
  transition: color 0.3s;
  color: rgba(1, 41, 112, 0.5);
  margin: 15px 12px;
  display: block;
  line-height: 0;
  text-align: center;
}
.team .member .social a:hover {
  color: rgba(1, 41, 112, 0.8);
}
.team .member .social i {
  font-size: 18px;
}
.team .member .member-info {
  padding: 10px 15px 20px 15px;
}
.team .member .member-info h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  color: #49bbb0;
}
.team .member .member-info span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #aaaaaa;
}
.team .member .member-info p {
  font-style: italic;
  font-size: 14px;
  padding-top: 15px;
  line-height: 26px;
  color: #5e5e5e;
}
.team .member:hover {
  transform: scale(1.08);
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}
.team .member:hover .social {
  right: 8px;
  opacity: 1;
}

.padding {
  padding-left: 5vh;
  padding-right: 5vh;
  background: #EFF8F7;
}

.padding-top {
  padding-top: 3vh;
}